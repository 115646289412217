import {
  HubspotIntegration,
  NotificationIntegration,
  NotificationIntegrationType,
  NotificationIntegrations,
  PipeDriveIntegration,
  SlackIntegration,
  SmsIntegration,
  WhatsAppIntegration,
  WorkizIntegration
} from "./notification-integration.type";

export class NotificationIntegrationFactory {
  private static createBaseIntegration(type: NotificationIntegrationType): NotificationIntegration {
    switch (type) {
      case NotificationIntegrationType.Sms:
        return {type, enabled: false} as SmsIntegration;
      case NotificationIntegrationType.WhatsApp:
        return {type, enabled: false} as WhatsAppIntegration;
      case NotificationIntegrationType.Hubspot:
        return {type, enabled: false} as HubspotIntegration;
      case NotificationIntegrationType.PipeDrive:
        return {type, enabled: false} as PipeDriveIntegration;
      case NotificationIntegrationType.Workiz:
        return {type, enabled: false} as WorkizIntegration;
      case NotificationIntegrationType.Slack:
        return {type, enabled: false} as SlackIntegration;
      default:
        throw new Error(`Unsupported type: ${type}`);
    }
  }

  public get baseIntegrations(): NotificationIntegrations {
    return Object.values(NotificationIntegrationType).map(type =>
      NotificationIntegrationFactory.createBaseIntegration(type)
    );
  }
}
